import React, { useState, useContext, useCallback, useRef } from 'react';
import StepButtons from '../../common/StepButtons';
import DynamicForm from '../../form/DynamicForm';
import ApplicationAgree from '../shared/ApplicationAgree';

import { UserContext } from '../../../context/UserContext';
import { globalObject } from '../../../globalVars';

/** services API */
import { submitVerification } from '../../../service/api';

// icons inputs
import iconProfile from '../../../assets/images/icons/icon-profile.svg';
import iconWork from '../../../assets/images/icons/icon-work.svg';
import iconMail from '../../../assets/images/icons/icon-mail.svg';

const Application = ({ handleGotoPrev, handleGotoNext }) => {
  const formRef = useRef();

  const [data, setData] = useState({
    firstName: globalObject.firstName,
    lastName: globalObject.lastName,
    companyName: globalObject.companyName,
    emailAddress: globalObject.emailAddress,
    mobileNumber: globalObject.mobileNumber,
    agree: false,
  });

  const formFields = [
    {
      name: 'firstName',
      type: 'text',
      label: '',
      placeholder: 'First Name',
      icon: iconProfile,
      required: true,
      value: data['firstName'],
    },
    {
      name: 'lastName',
      type: 'text',
      label: '',
      placeholder: 'Last Name',
      icon: iconProfile,
      required: true,
      value: data['lastName'],
    },
    {
      name: 'companyName',
      type: 'text',
      label: '',
      placeholder: 'Business Name',
      icon: iconWork,
      required: true,
      value: data['companyName'],
    },
    {
      name: 'emailAddress',
      type: 'email',
      label: '',
      placeholder: 'Email',
      icon: iconMail,
      required: true,
      value: data['emailAddress'],
    },
    {
      name: 'mobileNumber',
      type: 'phone',
      label: '',
      placeholder: '',
      icon: true,
      required: true,
      value: data['mobileNumber'],
    },
    {
      name: 'agree',
      type: 'checkbox',
      label: <ApplicationAgree />,
      placeholder: '',
      icon: '',
      required: true,
      value: data['agree'],
      isChecked: data['agree'],
      className: 'signupTermsConditions',
      validationMessage: 'You need to accept and agree to the Privacy and Consent Statement and Terms of Service',
    },
  ];
  const [dataError, setDataError] = useState({});
  const { error, updateContext } = useContext(UserContext);

  const handleFormDataChange = useCallback(({ name, value, error }) => {
    setData((data) => {
      if (data[name] !== value) {
        return { ...data, [name]: value };
      }
      return data;
    });
    setDataError((dataError) => ({ ...dataError, [name]: error }));
  }, []);

  const handleContinue = async () => {
    const isValid = formRef.current.validateForm();
    if (isValid) {
      updateContext({ loading: true, error: '', formData: data, mobileNumber: data.mobileNumber });
      try {
        const res = await submitVerification({
          mobile: data.mobileNumber,
        });
        if (res && res.data) {
          updateContext({ token: res.data.Token });
        }

        handleGotoNext();
      } catch (e) {
        if (e.response.data && e.response.data.error) {
          updateContext({ error: e.response.data.error });
        }
      }
      updateContext({ loading: false });
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={'first-step-container custom-step-content'}>
        <div className="body-custom-step-content">
          <div className="block-text-test">
            <p>
              <strong>Get approved</strong> within minutes to pay for business expenses with your{' '}
              <strong className="custom-mastercard-r">
                UnLock Mastercard<sup>&reg;</sup>
              </strong>
            </p>
          </div>

          <div className="form-info-block">
            <span>Account Application</span>
            <p>Please enter your details to begin</p>
          </div>
          <div className="px-10 pb-10">
            <DynamicForm ref={formRef} fields={formFields} onChange={handleFormDataChange}>
              {error && (
                <div className="flex w-full">
                  <p dangerouslySetInnerHTML={{ __html: error }} className="error-text-no-flex custom-error-text"></p>
                </div>
              )}
              <div className="flex w-full">
                <StepButtons handleGotoPrev={handleGotoPrev} handleGotoNext={handleContinue} className="w-full" />
              </div>
            </DynamicForm>
          </div>
        </div>
      </div>
    </>
  );
};

export default Application;
