import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
//Constands
import { randomName } from '../../constants/fields';

const InputCheckbox = React.memo(
  forwardRef(
    (
      {
        name,
        label,
        className,
        required,
        validationMessage,
        onChange,
        isValidateOnChange = false,
        value: externalValue,
      },
      ref
    ) => {
      const [inputName] = useState(name || randomName('Checkbox'));
      const [isValid, setIsValid] = useState(true);
      const [isInit, setIsInit] = useState(true);
      const [value, setValue] = useState(externalValue || false); // Set initial value
      const [error, setError] = useState('');

      useImperativeHandle(ref, () => ({
        validate: () => validateInput(value),
      }));

      const inputClasses = [
        'form-checkbox',
        'h-5',
        'w-5',
        'mt-1',
        error && !isValid ? 'border-red-500' : 'border-gray-300',
      ]
        .filter(Boolean)
        .join(' ');

      useEffect(() => {
        if (!isInit) {
          if (isValidateOnChange || !isValid) {
            validateInput(value);
          }
        }
        onChange && onChange({ name: inputName, value, error });
      }, [value, error, inputName, onChange, isInit]);

      const handleChange = (event) => {
        const inputValue = event.target.checked;
        setValue(inputValue);
        setIsInit(false);
        if (isValidateOnChange || !isValid) {
          validateInput(inputValue);
        }
      };

      const validateInput = (inputValue) => {
        let isValid = true;
        setError('');
        try {
          if (required && !inputValue) {
            setError(validationMessage || 'This field is required');
            isValid = false;
          }
        } catch (error) {
          setError(error);
          isValid = false;
        }
        setIsValid(isValid);
        return isValid;
      };
      return (
        <div className={`${className || 'w-full'} px-3 mb-2`}>
          <div className="flex">
            <div className="w-10 z-10 text-center flex items-center justify-center">
              <input
                id={inputName}
                name={inputName}
                ref={ref}
                defaultValue={value}
                onChange={handleChange}
                type="checkbox"
                className={inputClasses}
              />
            </div>
            <label htmlFor={name} className="flex-2 text-base">
              {label}
            </label>
          </div>
          {error && !isValid && <p className="w-100 m-0 text-sm text-red-500">{error}</p>}
        </div>
      );
    }
  )
);

export default InputCheckbox;
