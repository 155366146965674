import React, { useState, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import InputText from './InputText';
import InputPhone from './InputPhone';
import InputCheckbox from './InputCheckbox';
import InputSelect from './InputSelect';
import InputOtp from './InputOtp';
import InputDate from './InputDate';

//Constands
import { randomName } from '../../constants/fields';

const inputComponentMap = {
  text: InputText,
  email: InputText,
  phone: InputPhone,
  checkbox: InputCheckbox,
  select: InputSelect,
  otp: InputOtp,
  date: InputDate,
};

const DynamicForm = forwardRef(({ name, fields, onChange, children }, ref) => {
  const formRefs = useRef({});

  const [formName] = useState(name || randomName('Form'));

  const validateForm = useCallback(() => {
    let isValid = true;
    Object.keys(formRefs.current).forEach((key) => {
      if (formRefs.current[key] && formRefs.current[key].validate) {
        const validationResult = formRefs.current[key].validate();
        if (!validationResult) isValid = false;
      }
    });
    return isValid;
  }, []);

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  const handleChange = useCallback((e) => {
    onChange(e);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <form ref={ref} id={formName} name={formName} className="w-full" onSubmit={handleSubmit}>
      {fields.map((field, index) => {
        const InputComponent = inputComponentMap[field.type] || InputText;
        return (
          <div key={`${field.name}-${index}`} className="flex w-full">
            <InputComponent ref={(el) => (formRefs.current[field.name] = el)} onChange={handleChange} {...field} />
          </div>
        );
      })}
      {children}
    </form>
  );
});

export default DynamicForm;
