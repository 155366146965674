import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import OtpInput from 'react-otp-input';

//Constands
import { randomName } from '../../constants/fields';

const InputOtp = React.memo(
  forwardRef(
    (
      {
        name,
        numInputs,
        label,
        required,
        className = 'w-full',
        onChange,
        value: externalValue,
        isValidateOnChange = false,
      },
      ref
    ) => {
      const [inputName] = useState(name || randomName('Otp'));
      const [isValid, setIsValid] = useState(true);
      const [isInit, setIsInit] = useState(true);
      const [value, setValue] = useState(externalValue || ''); // Set initial value
      const [error, setError] = useState('');

      useImperativeHandle(ref, () => ({
        validate: () => validateInput(value),
      }));

      useEffect(() => {
        if (!isInit) {
          if (isValidateOnChange || !isValid) {
            validateInput(value);
          }
        }
        onChange && onChange({ name: inputName, value, error });
      }, [value, error, inputName, onChange, isInit]);

      const handleChange = (inputValue) => {
        setIsInit(false);
        setValue(inputValue);
      };

      const validateInput = (inputValue) => {
        let isValid = true;
        setError('');
        try {
          if (required && inputValue.trim().length != parseInt(numInputs)) {
            setError(`Ensure you enter all ${numInputs} digits of the OTP provided.`);
            isValid = false;
          }
        } catch (error) {
          setError(error);
          isValid = false;
        }
        setIsValid(isValid);
        return isValid;
      };

      return (
        <div className={`${className} px-3 mb-2`}>
          {label && (
            <label htmlFor={label} className="text-xs font-semibold px-1">
              {label}
            </label>
          )}
          <div className="flex">
            <OtpInput
              id={inputName}
              name={inputName}
              ref={ref}
              value={value}
              onChange={handleChange}
              numInputs={numInputs}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputType="number"
              className={className}
            />
          </div>
          {error && !isValid && <p className="w-100 m-0 text-sm text-red-500">{error}</p>}
        </div>
      );
    }
  )
);

export default InputOtp;
