import React from 'react';

const ApplicationAgree = () => {
  return (
    <>
      I accept and agree to the{' '}
      <a href="https://unlockb2b.com/privacy-policy/" target="_blank" rel="noreferrer">
        Privacy and Consent Statement
      </a>{' '}
      and{' '}
      <a
        href="https://unlockb2b.com/wp-content/uploads/2021/09/UnLock-Terms-of-Service-v.4-21-09-21.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
      .
    </>
  );
};
export default ApplicationAgree;
