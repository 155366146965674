import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import PageHeader from './PageHeader';
import ApplicationLoader from './ApplicationLoader';

const Layout = ({ children, title }) => {
  const { loading } = useContext(UserContext);

  return (
    <div className="page-layout">
      <div className="static-block">
        <p>Get approved within minutes to pay for business expenses</p>
        <p>
          with your <strong>UnLock Mastercard&reg;</strong>
        </p>
      </div>

      {loading && <ApplicationLoader />}

      <PageHeader title={title} />

      <div className="layout-content">{children}</div>
    </div>
  );
};

export default Layout;
