import React from 'react';
const renderPixelcodes = () => {
  return (
    <>
      <script
        language="JavaScript1.1"
        async
        src="//pixel.mathtag.com/event/js?mt_id=1611849&mt_adid=257068&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
      ></script>
    </>
  );
};
export default renderPixelcodes;
