import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import useSignupLocalData from '../hooks/SignupLocalDataHook';

import axios from 'axios';

/**
 * @typedef {object} DecodedToken
 * @property {string} dealId
 * @property {string} FailMessage
 */

/**
 * @typedef {object} SelectedCompany
 * @property {string} ACN
 * @property {string} VedaBusinessNameID
 */

/**
 * @typedef {('default' | 'businessCheckFailed' | 'creditCheckFailed')} FormPath
 */

/**
 * @typedef {object} Director
 * @property {string} Name
 * @property {string} DateOfBirth
 * @property {string} Address
 */

/**
 * @callback UpdateContext
 * @param {UserContextUpdate} data
 * @returns void
 */

/**
 * @typedef {object} UserContext
 * @property {FormPath} formPath
 * @property {boolean} loading
 * @property {string} customLoadingText
 * @property {string} token
 * @property {(DecodedToken | null)} decodedToken
 * @property {number} width
 * @property {SelectedCompany} companyData
 * @property {Director[]} directors
 * @property {string} content
 * @property {string} dealId
 * @property {string} error
 * @property {(number | null)} activeIndexPage
 * @property {UpdateContext} updateContext
 * @property {boolean} isReferred
 * @property {boolean} renderPixel
 */

/**
  @type {UserContext}
 */

// Define default context
export const defaultContext = {
  formPath: '',
  loading: false,
  customLoadingText: '',
  token: '',
  decodedToken: null,
  width: window.innerWidth,
  companyData: {},
  directors: [
    {
      Name: '',
      DateOfBirth: '',
      Address: '',
    },
    {
      Name: '',
      DateOfBirth: '',
      Address: '',
    },
  ],
  content: '',
  dealId: '',
  error: '',
  activeIndexPage: 0,
  hasAdditionalStep: false,
  formData: {},
  isReferred: false,
  renderPixel: false,
  updateContext: (update) => undefined,
};

export const UserContext = React.createContext(defaultContext);

export default function UserProvider({ children }) {
  const { storedValue, setValue } = useSignupLocalData();
  let mergedContext = defaultContext;
  const history = useHistory();
  if (storedValue) {
    mergedContext = { ...defaultContext, ...storedValue };
    //mergedContext = { ...defaultContext };
  }

  const [context, setContext] = useState({
    ...mergedContext,
    updateContext: (update) => {
      setContext((prevContext) => {
        const updatedContext = { ...prevContext, ...update };
        setValue(updatedContext);
        return updatedContext;
      });
    },
  });
  const { updateContext } = context;

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        updateContext({ loading: false });
        console.log(error, error.response, 'error');
        if (!error.response) {
          history.push('/server-error');
        }
        return Promise.reject(error);
      }
    );
  }, []);

  /** For Rendering different components based on breakpoint */
  useEffect(() => {
    function handleResize() {
      updateContext({ width: window.innerWidth });
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Store current token to local storage
    localStorage.setItem('unlock_access_token', context.token);
  }, [context.token]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
