import React, { useState, useContext, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import DynamicForm from '../../form/DynamicForm';
import Button from '../../form/Button';
import StepButtons from '../../common/StepButtons';
import CompanyDesktopTable from '../shared/CompanyDesktopTable';
import CompanyMobileTable from '../shared/CompanyMobileTable';
import renderPixelcodes from '../shared/renderPixelcodes';

import { UserContext } from '../../../context/UserContext';

/** services */
import { searchCompanies, selectCompany } from '../../../service/api';

import iconSearch from '../../../assets/images/icons/icon-search.svg';

const CompanyIdentification = ({ handleGotoPrev, handleGotoNext }) => {
  const history = useHistory();
  const formRef = useRef();

  const [results, setResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [search, setSearch] = useState('');
  const [searchCounter, setSearchCounter] = useState(0);
  const { error, width, updateContext, activeIndexPage, renderPixel } = useContext(UserContext);

  const formFields = [
    {
      name: 'company',
      type: 'text',
      label: '',
      placeholder: 'Company Name, ABN or ACN',
      icon: iconSearch,
      required: true,
      value: search,
      validationMessage: 'Please enter a key to search.',
    },
  ];

  const handleFormDataChange = useCallback(({ name, value, error }) => {
    setSearch(value);
  }, []);

  const handleSearch = async () => {
    const isValid = formRef.current.validateForm();
    if (isValid) {
      updateContext({
        loading: true,
        customLoadingText: 'Processing your Application ...',
        error: '',
      });
      setSearchCounter(0);
      try {
        const res = await searchCompanies({
          name: search,
        });

        if (res && res.data) {
          setResults(res.data.Data ? res.data.Data : []);
          updateContext({
            content: res.data.Content,
            token: res.data.Token,
          });
          setSearchCounter(searchCounter + 1);
        }
      } catch (error) {
        console.log(error);
        if (error.response.data && error.response.data.error) {
          updateContext({ error: error.response.data.error });
          history.push('/error');
        }
      }
      updateContext({
        loading: false,
        customLoadingText: '',
      });
    } else {
      setSearchCounter(0);
    }
  };

  const handleContinue = async () => {
    updateContext({
      companyData: selectedCompany,
    });
    if (!selectedCompany) {
      return false;
    }
    updateContext({
      loading: true,
      customLoadingText: 'Your UnLock Account Application is being processed...',
      error: '',
    });
    try {
      const res = await selectCompany({
        ACN: selectedCompany.ACN,
        VedaBusinessNameID: selectedCompany.VedaBusinessNameID,
        FullJson: JSON.stringify(selectedCompany),
      });

      if (res && res.data) {
        const isNextAdditionalStep = jwt_decode(res.data.Token)?.step == 'additional-info';
        updateContext({
          token: res.data.Token,
          content: res.data.Content,
          formPath: isNextAdditionalStep ? 'additionals' : 'default',
          activeIndexPage: isNextAdditionalStep ? 0 : activeIndexPage + 1,
          hasAdditionalStep: isNextAdditionalStep,
        });
      }
    } catch (error) {
      if (error.response.data && error.response.data.error) {
        updateContext({ error: error.response.data.error });
      }
    }
    updateContext({
      loading: false,
      customLoadingText: '',
    });
  };

  return (
    <div className="custom-steps company-identification">
      <div className="custom-navigation-block">
        <span>YOUR DETAILS</span>
      </div>
      <div className="block-position-center">
        <div className="business-list">
          <div className="form-step-wrapper form-step-wrapper-two">
            <div className="form-group">
              <div className="form-info-block">
                <span>Verify Your Business</span>
                <p>Please enter Company Name, ABN or ACN</p>
              </div>
              <DynamicForm ref={formRef} fields={formFields} onChange={handleFormDataChange}>
                {error && (
                  <div className="flex w-full">
                    <p dangerouslySetInnerHTML={{ __html: error }} className="error-text-no-flex custom-error-text"></p>
                  </div>
                )}
                <div className="flex w-full">
                  <Button value="Search" buttonClass="primary-btn-business-verification" onClick={handleSearch} />
                </div>
              </DynamicForm>
            </div>

            {searchCounter > 0 ? (
              <div className="search-results">
                {/* <h3>Select your business from the results below</h3> */}
                {results.length > 0 ? (
                  <>
                    {width > 768 ? (
                      <CompanyDesktopTable results={results} setSelectedCompany={setSelectedCompany} />
                    ) : (
                      <CompanyMobileTable results={results} setSelectedCompany={setSelectedCompany} />
                    )}
                  </>
                ) : (
                  <p className="search-results-error">
                    'We could not find any companies that match the search values. Please try another search.'
                  </p>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          {renderPixel && renderPixelcodes()}
        </div>
        {results.length > 0 && (
          <div className="flex w-full">
            <div className="business-action">
              <div className="custom-button-form-result">
                <StepButtons nextBtnText="Confirm" handleGotoNext={handleContinue} isNextDisabled={!selectedCompany} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyIdentification;
