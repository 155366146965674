import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
//Constands
import { emailPattern, mobileNumberPattern, randomName } from '../../constants/fields';
const emailPatternRegExp = new RegExp(emailPattern);

const InputText = React.memo(
  forwardRef(
    (
      {
        name,
        type,
        label,
        placeholder,
        icon,
        pattern,
        required,
        autoComplete,
        className = 'w-full',
        validationMessage,
        onChange,
        value: externalValue,
        isValidateOnChange = false,
      },
      ref
    ) => {
      const [inputName] = useState(name || randomName('Text'));
      const [isValid, setIsValid] = useState(true);
      const [isInit, setIsInit] = useState(true);
      const [value, setValue] = useState(externalValue || ''); // Set initial value
      const [error, setError] = useState('');
      const [isFocused, setIsFocused] = useState(false);

      useImperativeHandle(ref, () => ({
        validate: () => validateInput(value),
      }));

      const inputClasses = [
        'w-full',
        '-ml-10',
        'pl-10',
        'pr-3',
        'py-2',
        'leading-tight',
        'focus:outline-none',
        error && !isValid ? 'border-red-500' : '',
      ]
        .filter(Boolean)
        .join(' ');

      useEffect(() => {
        if (!isInit) {
          if (isValidateOnChange || !isValid) {
            validateInput(value);
          }
        }
        onChange && onChange({ name: inputName, value, error });
      }, [value, error, inputName, onChange, isInit]);

      const handleChange = (event) => {
        const inputValue = event.target.value;
        setIsInit(false);
        setValue(inputValue);
      };

      const handleBlur = (event) => {
        const inputValue = event.target.value;
        setIsInit(false);
        setIsFocused(false);
        if (isValidateOnChange || !isValid) {
          validateInput(inputValue);
        }
      };

      const handleFocus = (event) => {
        const inputValue = event.target.value;
        setIsInit(false);
        setIsFocused(true);
        if (isValidateOnChange || !isValid) {
          validateInput(inputValue);
        }
      };

      const validateInput = (inputValue) => {
        let isValid = true;
        setError('');
        try {
          if (required && inputValue.trim() === '') {
            setError(validationMessage || 'This field is required');
            isValid = false;
          } else if (!pattern && type == 'email' && !emailPatternRegExp.test(String(inputValue).toLowerCase())) {
            setError(validationMessage || 'Invalid email');
            isValid = false;
          } else if (!pattern && type == 'mobile' && !mobileNumberPattern.test(String(inputValue))) {
            setError(validationMessage || 'Invalid mobile');
            isValid = false;
          } else if (pattern && !pattern.test(String(inputValue))) {
            setError(validationMessage || 'Invalid input');
            isValid = false;
          }
        } catch (error) {
          setError(error);
          isValid = false;
        }
        setIsValid(isValid);
        return isValid;
      };

      return (
        <div className={`${className || 'w-full'} px-3 mb-2`}>
          {label && (
            <label htmlFor={label} className="text-xs font-semibold px-1">
              {label}
            </label>
          )}
          <div className="flex">
            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
              {icon && <img src={icon} alt="icon-image" />}
            </div>
            <input
              id={inputName}
              name={inputName}
              ref={ref}
              type={type || 'text'}
              className={inputClasses}
              placeholder={placeholder || ''}
              autoComplete={autoComplete || 'on'}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          </div>
          {error && isFocused && !isValid && <p className="w-100 m-0 text-sm text-red-500">{error}</p>}
        </div>
      );
    }
  )
);

export default InputText;
