import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import InputMask from 'react-input-mask';
//Constands
import { mobileNumberPattern, randomName } from '../../constants/fields';
//icon input
import iconCall from '../../assets/images/icons/icon-call.svg';

const InputPhone = React.memo(
  forwardRef(
    (
      {
        name,
        label,
        icon,
        required,
        className,
        validationMessage,
        onChange,
        value: externalValue,
        isValidateOnChange = false,
      },
      ref
    ) => {
      const [inputName] = useState(name || randomName('Phone'));
      const [isValid, setIsValid] = useState(true);
      const [isInit, setIsInit] = useState(true);
      const [value, setValue] = useState(externalValue || '+61'); // Set initial value
      const [error, setError] = useState('');
      const [isFocused, setIsFocused] = useState(false);

      useImperativeHandle(ref, () => ({
        validate: () => validateInput(value),
      }));

      const inputClasses = [
        'w-full',
        '-ml-10',
        'pl-10',
        'pr-3',
        'py-2',
        'leading-tight',
        'focus:outline-none',
        error && !isValid ? 'border-red-500' : '',
      ]
        .filter(Boolean)
        .join(' ');

      useEffect(() => {
        if (!isInit) {
          if (isValidateOnChange || !isValid) {
            validateInput(value);
          }
        }
        onChange && onChange({ name: inputName, value, error });
      }, [value, error, inputName, onChange, isInit]);

      const handleChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.startsWith('+61')) {
          setValue(inputValue);
          setIsInit(false);
        } else {
          // If the user deleted +61, start printing again
          setValue('+61');
          setIsInit(false);
        }
      };

      const handleBlur = (event) => {
        const inputValue = event.target.value;
        setIsInit(false);
        setIsFocused(false);
        if (isValidateOnChange || !isValid) {
          validateInput(inputValue);
        }
      };

      const handleFocus = (event) => {
        const inputValue = event.target.value;
        setIsInit(false);
        setIsFocused(false);
        if (isValidateOnChange || !isValid) {
          validateInput(inputValue);
        }
      };

      const validatePhoneNumber = (value) => {
        const re = new RegExp(mobileNumberPattern);
        return re.test(value);
      };

      const formatPhoneNumber = (input) => {
        // Remove all non-digit characters
        let digits = input.replace(/\D/g, '');
        // Check if the digits length is valid for Australian numbers
        if (digits.length !== 11) {
          throw new Error('Invalid phone number length');
        }
        // Format the number
        const countryCode = digits.slice(0, 2); // +61
        const part1 = digits.slice(2, 5); // 454
        const part2 = digits.slice(5, 8); // 543
        const part3 = digits.slice(8); // 545

        return `+${countryCode}-${part1}-${part2}-${part3}`;
      };

      const validateInput = (inputValue) => {
        let isValid = true;
        const phoneNumber = formatPhoneNumber(inputValue);
        setError('');
        try {
          if (required && phoneNumber.trim() === '') {
            setError(validationMessage || 'This field is required');
            isValid = false;
          } else if (required && !validatePhoneNumber(phoneNumber)) {
            setError(validationMessage || 'Format is incorrect');
            isValid = false;
          }
        } catch (error) {
          setError(error);
          isValid = false;
        }
        setIsValid(isValid);
        return isValid;
      };

      return (
        <div className={`${className || 'w-full'} px-3 mb-2`}>
          {label && (
            <label htmlFor={label} className="text-xs font-semibold px-1">
              {label}
            </label>
          )}
          <div className="flex">
            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
              {icon && <img src={iconCall} alt="icon-image" />}
            </div>
            <InputMask
              id={inputName}
              name={inputName}
              ref={ref}
              className={inputClasses}
              mask={'+99-999-999-999'}
              autoComplete="off"
              value={value ? value : '+61'}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              placeholder={''}
            />
          </div>
          {error && isFocused && !isValid && <p className="w-100 m-0 text-sm text-red-500">{error}</p>}
        </div>
      );
    }
  )
);

export default InputPhone;
