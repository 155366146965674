import React from 'react';
const CompanyDesktopTable = ({ results, setSelectedCompany }) => {
  return (
    <table className="companies-table 123" style={{ fontSize: 'x-small' }}>
      <thead>
        <tr>
          <td className="select-col">&nbsp;&nbsp;</td>
          <td>Name</td>
          <td>ABN</td>
          <td>ACN</td>
          <td>Status</td>
          <td>Type</td>
          <td>Registered Date</td>
          <td>Trading Names</td>
          <td>Address</td>
        </tr>
      </thead>
      <tbody>
        {results.map((row, index) => {
          return (
            <tr key={row.ACN + index}>
              <td className="select-col">
                {/* {row.FailMessage ? <span data-tip={row.FailMessage}>unfit</span> : ''} */}
                <input
                  id={`${index}`}
                  name={'selectedCompany'}
                  type="radio"
                  value={row.ACN}
                  onClick={() => setSelectedCompany(row)}
                />
              </td>
              <td>{row.Name}</td>
              <td>{row.ABN}</td>
              <td>{row.ACN}</td>
              <td>{row.Status}</td>
              <td>{row.Type}</td>
              <td>{row.AsicRegistrationDate}</td>
              <td>{row.TradingNames}</td>
              <td>{row.Address}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default CompanyDesktopTable;
