import React, { useState, useContext, useCallback, useRef } from 'react';
import DynamicForm from '../../form/DynamicForm';
import Button from '../../form/Button';

import { UserContext } from '../../../context/UserContext';

/** services */
import { submitDetails, submitVerification } from '../../../service/api';

const Verification = ({ handleGotoNext }) => {
  const { error, mobileNumber, formData, updateContext } = useContext(UserContext);

  const [userFormData, setUserFormData] = useState({ ...formData });
  const [changeMobile, setChangeMobile] = useState(false);

  const formRef = useRef({});
  const formMobileRef = useRef({});

  const [data, setData] = useState({
    mobileNumber: mobileNumber,
    code: '',
  });
  const [dataError, setDataError] = useState({});

  const formMobileFields = [
    {
      name: 'mobileNumber',
      type: 'phone',
      label: '',
      placeholder: '',
      required: true,
      icon: true,
      value: data['mobileNumber'],
      numInputs: 6,
    },
  ];

  const formFields = [
    {
      name: 'code',
      type: 'otp',
      label: '',
      placeholder: '',
      required: true,
      value: data['code'],
      numInputs: 6,
      className: 'class-input-code',
    },
  ];

  const handleFormDataChange = useCallback(({ name, value, error }) => {
    setData((data) => {
      if (data[name] !== value) {
        return { ...data, [name]: value };
      }
      return data;
    });
    setDataError((dataError) => ({ ...dataError, [name]: error }));
  }, []);

  const handleClearMobile = () => {
    setChangeMobile(true);
    //setData({ ...data, mobileNumber: '+61' }); //If need to clear the existing number
  };

  const handleConfirm = async () => {
    let isMobileValid = true;
    if (changeMobile) {
      isMobileValid = formMobileRef.current.validateForm();
    }
    const isValid = formRef.current.validateForm();
    if (isValid && isMobileValid) {
      updateContext({ loading: true, error: '' });
      try {
        const res = await submitDetails({
          company_name: formData.companyName,
          email: formData.emailAddress,
          first_name: formData.firstName,
          last_name: formData.lastName,
          mobile: data.mobileNumber.replaceAll('-', ''),
          agree: formData.agree,
          verification_code: data.code,
        });

        if (res && res.data) {
          updateContext({ token: res.data.Token, content: res.data.Content });
        }
        handleGotoNext();
      } catch (error) {
        if (error.response.data && error.response.data.error) {
          updateContext({ error: error.response.data.error });
        }
      }
      updateContext({ loading: false });
    }
    return false;
  };

  const handleResendCode = async () => {
    if (!changeMobile) {
      setChangeMobile(false);
      updateContext({ loading: true, error: '', mobileNumber: data.mobileNumber });
      const res = await submitVerification({
        mobile: data.mobileNumber,
      });
      if (res && res.data) {
        updateContext({ token: res.data.Token });
      }
      updateContext({ loading: false });
    }
    return false;
  };

  const handleAmendMobileNumber = async () => {
    const isValid = formMobileRef.current.validateForm();
    if (isValid) {
      setChangeMobile(false);
      setUserFormData({ ...userFormData, mobileNumber: data.mobileNumber });
      updateContext({
        loading: true,
        error: '',
        mobileNumber: data.mobileNumber,
        formData: { ...formData, mobileNumber: data.mobileNumber },
      });

      const res = await submitVerification({
        mobile: data.mobileNumber,
      });
      if (res && res.data) {
        updateContext({ token: res.data.Token });
      }
      // we stay on the same page/step
      window.scrollTo(0, 0);

      updateContext({ loading: false });
    }
    return false;
  };

  return (
    <>
      <div className="verification-container">
        <div className="block-position-center">
          <div className="form-step-wrapper">
            <div className={`form-group ${dataError['mobileNumber'] ? 'error' : ''}`}>
              <div className="test-nob-block">
                <div>
                  <span>Phone Verification</span>
                </div>
                <div>
                  <p>Please enter the verification code send to your mobile phone number to continue.</p>
                </div>
                <div>
                  <p>Your code is send to the number.</p>
                </div>
              </div>
              <div className="flex w-full">
                {changeMobile ? (
                  <DynamicForm ref={formMobileRef} fields={formMobileFields} onChange={handleFormDataChange}>
                    <div className="flex w-full">
                      <Button value="Amend Mobile No." onClick={handleAmendMobileNumber} />
                    </div>
                  </DynamicForm>
                ) : (
                  <div className="flex w-full">
                    <div className="flex-1 verification-verified-mobile">{data['mobileNumber']}</div>
                    <div className="w-auto ml-auto">
                      <Button
                        value="Change"
                        buttonClass="btn-verified-change"
                        isContainer={false}
                        onClick={handleClearMobile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={`form-group ${dataError['code'] ? 'error' : ''}`}>
              <div className="test-nob-block">
                <div>
                  <p>
                    A message with the verification code has been sent to your mobile number. Enter the code to
                    continue.
                  </p>
                </div>
              </div>
              <DynamicForm ref={formRef} fields={formFields} onChange={handleFormDataChange}>
                <div className="flex w-full">
                  <Button
                    value="Resend Code"
                    isPrimary={false}
                    buttonClass="text-blue-500 focus:outline-none ml-auto border-none bg-transparent resend-code-link w-50"
                    isContainer={false}
                    onClick={handleResendCode}
                  />
                </div>
                {error && (
                  <div className="flex w-full mt-3">
                    <p dangerouslySetInnerHTML={{ __html: error }} className="error-text-no-flex custom-error-text"></p>
                  </div>
                )}
                <div className="flex w-full mt-5">
                  <Button value="Confirm" className="validation-btns w-full" onClick={handleConfirm} />
                </div>
              </DynamicForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
