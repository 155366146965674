import React, { useRef } from 'react';
import { randomName } from '../../constants/fields';

const Button = React.memo(
  ({
    value: externalValue,
    name,
    className = 'w-full',
    buttonClass = '',
    isPrimary = true,
    onClick,
    isContainer = true,
    type = 'Button',
  }) => {
    const nameRef = useRef(name || randomName(type));
    const handleClick = (event) => {
      if (onClick) onClick(event);
    };
    if (isContainer) {
      return (
        <>
          <div className={`${className} px-3 mb-2`}>
            <button
              id={nameRef.current}
              name={nameRef.current}
              onClick={handleClick}
              className={`${isPrimary && 'primary-btn dark w-100'} ${buttonClass}`}
            >
              {`${externalValue}`}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <button
            id={nameRef.current}
            name={nameRef.current}
            onClick={handleClick}
            className={`${isPrimary && 'primary-btn dark w-100'} ${buttonClass}`}
          >
            {`${externalValue}`}
          </button>
        </>
      );
    }
  }
);

export default Button;
