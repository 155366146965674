import React, { useState, useEffect, useContext} from 'react';
import { UserContext } from '../../context/UserContext';
// icons inputs
import loaderIconDesktop from '../../assets/images/loading_icon_desktop.gif';
import loaderIconMobile from '../../assets/images/loading_icon_mobile.gif';

const ApplicationLoader = () => {
    const { width } = useContext(UserContext);

    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(updateCounter, 600);
        return () => {
            clearInterval(interval);
        };
    }, [count]);
    
    const updateCounter = () => {
        if (count < 100) {
            setCount((prevCount) => prevCount + 1);
        } else {
            setCount(0);
        }
    };

    const progressBarStyle = {
        width: `${count}%`,
    };

    return (
        <div className="custom-spinner">
            <div id="container">
                {/*<div id="counter">{`${count}%`}</div>*/}
                <div id="counter">  
                    {width > 768 ? (
                        <img src={loaderIconDesktop} className='loader-desktop' alt="icon-loader"/>
                    ) : (
                        <img src={loaderIconMobile} className='loader-mobile' alt="icon-loader"/>
                    )}
                </div>
                <div id="progress-container">
                    <div id="progress-bar" style={progressBarStyle}></div>
                </div>
                <div>
                    <p>Processing your Application ...</p>
                </div>
            </div>
        </div>
    )
};
  
export default ApplicationLoader;