// import React, { useContext, useEffect, useRef, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import jwtDecode from 'jwt-decode';
// import { UserContext } from '../../../context/UserContext';

// import StepButtons from '../../common/StepButtons';

// /** services */
// import { getDirectors } from '../../../service/api';
// import { BrowserView, MobileView } from 'react-device-detect';
// import mobileHero from '../../../assets/images/congrats_desktop-2.svg';
// import desktopHero from '../../../assets/images/congrats_desktop-2.svg';
// import { render } from '@testing-library/react';

// const Congratulations = ({ handleGotoPrev, handleGotoNext }) => {

//   const history = useHistory();

//   const contextDataRef = useRef();
//   const contextData = useContext(UserContext);
//   contextDataRef.current = contextData;

//   const { content, token, companyData, hasAdditionalStep, directors, error, updateContext, renderPixel } =
//     contextDataRef.current;

//   const decodedToken = token ? jwtDecode(token) : null;

//   useEffect(() => {
//     // if (hasAdditionalStep) {
//     //   return;
//     // }
//     console.log(companyData, decodedToken);
//     if (companyData.FailMessage && !hasAdditionalStep) {
//       updateContext({ formPath: 'businessCheckFailed', activeIndexPage: 0 });
//     } else if (!decodedToken?.dealId) {
//       updateContext({ formPath: 'creditCheckFailed', activeIndexPage: 0 });
//     }
//   }, [decodedToken]);

//   const handleContinue = async () => {
//     updateContext({ loading: true, error: '' });
//     try {
//       const res = await getDirectors();

//       if (res && res.data) {
//         updateContext({
//           token: res.data.Token,
//           directors: hasAdditionalStep ? directors : res.data.Directors ? res.data.Directors : [],
//         });
//       }
//       handleGotoNext();
//     } catch (error) {
//       if (error.response.data && error.response.data.error) {
//         updateContext({ error: error.response.data.error, directors: [] });
//         history.push('/error');
//       }
//     }
//     updateContext({ loading: false });
//   };

  

//   const renderPixelcodes = () => {

//     return (
//       <>
//         <script
//           language="JavaScript1.1"
//           async
//           src="//pixel.mathtag.com/event/js?mt_id=1611850&mt_adid=257068&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
//         ></script>
//         {/* <script language='JavaScript1.1' async src='//pixel.mathtag.com/event/js?mt_id=1611849&mt_adid=257068&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3='></script> */}
//       </>
//     );
//   };

//   return (
//     <div className="custom-steps">
//       <MobileView className="hero-image hero-image-custom-two hero-image-custom">
//         <img src={mobileHero} alt="Hero image" />
//       </MobileView>
//       <BrowserView className="hero-image hero-image-custom">
//         <img src={desktopHero} alt="Hero image" />
//       </BrowserView>
//       {/* {decodedToken?.dealId || hasAdditionalStep ? (
//         <div
//           className={`title-section ${decodedToken?.dealId ? '' : 'small'}`}
//           dangerouslySetInnerHTML={{ __html: content }}
//         ></div>
//       ) : (
//         <div></div>
//       )} */}
//       <div className='title-section title-section-custom small custom-title-color'>

  

//         <h3>You’ve been approved for<br/> a $5,000 UnLock Mastercard</h3>

//           <br/>
//         <span>Wait, we need a few more details to get your</span>
//           {/* <br/> */}
//         <span>UnLock Mastercard set up</span>
//       </div>
//       {renderPixel && renderPixelcodes()}
//       {error && <p className="error-text">{error}</p>}
//       <div className='custom-button-position'>
//         {decodedToken?.dealId ? <StepButtons handleGotoPrev={handleGotoPrev} handleGotoNext={handleContinue} /> : ''}
//       </div>
//     </div>
//   );


// };

// export default Congratulations;


// dynamic component 5K data 


import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { UserContext } from '../../../context/UserContext';
import useSignupLocalData from '../../../hooks/SignupLocalDataHook';
import StepButtons from '../../common/StepButtons';
import { getDirectors } from '../../../service/api';
import { BrowserView, MobileView } from 'react-device-detect';
import mobileHero from '../../../assets/images/congrats_desktop-2.svg';
import desktopHero from '../../../assets/images/congrats_desktop-2.svg';

const Congratulations = ({ handleGotoPrev, handleGotoNext }) => {
  const history = useHistory();
  const contextDataRef = useRef();
  const { removeValue } = useSignupLocalData();
  const contextData = useContext(UserContext);
  contextDataRef.current = contextData;

  const { content, token, dealId, companyData, hasAdditionalStep, directors, error, updateContext, renderPixel } =
    contextDataRef.current;

  const decodedToken = token ? jwtDecode(token) : null;
  const [approvedContent, setApprovedContent] = useState('');

  useEffect(() => {
    if (companyData.FailMessage && !hasAdditionalStep) {
      updateContext({ formPath: 'businessCheckFailed', activeIndexPage: 0 });
      removeValue();
    } else if (decodedToken?.dealId) {
      updateContext({ dealId: decodedToken.dealId });
    } else if (!decodedToken?.dealId && !dealId) {
      updateContext({ formPath: 'creditCheckFailed', activeIndexPage: 0 });
      removeValue();
    } 
  }, []);

  useEffect(() => {
    // Check if there is data in the "Content" field and if so, set it to the state
    if (content) {
      setApprovedContent(content);
    }
  }, [content]);

  const handleContinue = async () => {
    updateContext({ loading: true, error: '' });
    try {
      const res = await getDirectors();

      if (res && res.data) {
        updateContext({
          token: res.data.Token,
          directors: hasAdditionalStep ? directors : res.data.Directors ? res.data.Directors : [],
        });
      }
      handleGotoNext();
    } catch (error) {
      if (error.response.data && error.response.data.error) {
        updateContext({ error: error.response.data.error, directors: [] });
        history.push('/error');
      }
    }
    updateContext({ loading: false });
  };

  const renderPixelcodes = () => {
    return (
      <>
        <script
          language="JavaScript1.1"
          async
          src="//pixel.mathtag.com/event/js?mt_id=1611850&mt_adid=257068&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
        ></script>
      </>
    );
  };

  return (
    <div className="custom-steps">
      <MobileView className="hero-image hero-image-custom-two hero-image-custom">
        <img src={mobileHero} alt="Hero image" />
      </MobileView>
      <BrowserView className="hero-image hero-image-custom">
        <img src={desktopHero} alt="Hero image" />
      </BrowserView>
      {approvedContent && (
        <div className='title-section title-section-custom small custom-title-color' dangerouslySetInnerHTML={{ __html: approvedContent }}></div>
      )}
      {renderPixel && renderPixelcodes()}
      {error && <p className="error-text">{error}</p>}
      <div className='custom-button-position'>
        {decodedToken?.dealId || dealId ? <StepButtons handleGotoPrev={handleGotoPrev} handleGotoNext={handleContinue} /> : ''}
      </div>
    </div>
  );
};

export default Congratulations;
