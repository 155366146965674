import React, { useState, useContext, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import DynamicForm from '../../form/DynamicForm';
import StepButtons from '../../common/StepButtons';
import { UserContext } from '../../../context/UserContext';
import { submitAdditionalInfo } from '../../../service/api';

// icons inputs
import iconProfile from '../../../assets/images/icons/icon-profile.svg';
import iconLocation from '../../../assets/images/icons/icon-location.svg';
import iconBadge from '../../../assets/images/icons/icon-bage.svg';

const stateOptions = [
  { label: 'Select State', value: '' },
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Northern Territory', value: 'NT' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
];

const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Gender', value: 'U' },
];

const AdditionalInput = ({ handleGotoPrev, validateEmail, validateMaskInput, renderLabel }) => {
  const formRef = useRef();

  const [startDate] = useState(new Date(new Date().getFullYear() - 30, new Date().getMonth(), new Date().getDate()));

  const { error, formData, updateContext } = useContext(UserContext);
  const history = useHistory();
  const [data, setData] = useState({
    GivenName: formData.firstName,
    FamilyName: formData.lastName,
    StreetNumber: '',
    StreetName: '',
    Suburb: '',
    State: '',
    Postcode: '',
    DriversLicence: '',
    GenderCode: 'U',
    DateOfBirth: startDate,
  });
  const [dataError, setDataError] = useState({});

  const formFields = [
    {
      name: 'FamilyName',
      value: data.FamilyName,
      type: 'text',
      label: '',
      placeholder: 'First Name',
      icon: iconProfile,
      required: true,
    },
    {
      name: 'GivenName',
      value: data.GivenName,
      type: 'text',
      label: '',
      placeholder: 'Surname',
      icon: iconProfile,
      required: true,
    },
    {
      name: 'StreetNumber',
      value: data.StreetNumber,
      type: 'text',
      label: '',
      placeholder: 'Street No',
      icon: iconLocation,
      required: true,
    },
    {
      name: 'StreetName',
      value: data.StreetName,
      type: 'text',
      label: '',
      placeholder: 'Street Name',
      icon: iconLocation,
      required: true,
    },
    {
      name: 'Suburb',
      value: data.Suburb,
      type: 'text',
      label: '',
      placeholder: 'Suburb',
      icon: iconLocation,
      required: true,
    },
    {
      name: 'State',
      value: data.State,
      type: 'select',
      label: '',
      placeholder: 'State',
      type: 'select',
      required: true,
      options: stateOptions,
      icon: iconLocation,
    },
    {
      name: 'Postcode',
      value: data.Postcode,
      type: 'text',
      label: '',
      placeholder: 'Postcode',
      icon: iconLocation,
      required: true,
    },
    {
      name: 'DriversLicence',
      value: data.DriversLicence,
      type: 'text',
      label: '',
      placeholder: 'Drivers Licence',
      icon: iconBadge,
    },
    {
      name: 'GenderCode',
      value: data.GenderCode,
      type: 'select',
      label: '',
      placeholder: 'Gender',
      options: genderOptions,
      icon: iconProfile,
    },
    { name: 'DateOfBirth', value: data.DateOfBirth, type: 'date', label: 'DOB', placeholder: 'Date of Birth' },
  ];

  const handleFormDataChange = useCallback(({ name, value, error }) => {
    setData((data) => {
      if (data[name] !== value) {
        return { ...data, [name]: value };
      }
      return data;
    });
    setDataError((dataError) => ({ ...dataError, [name]: error }));
  }, []);

  const handleContinue = async () => {
    const isValid = formRef.current.validateForm();
    if (isValid) {
      updateContext({ loading: true, error: '' });
      try {
        const res = await submitAdditionalInfo({
          ...data,
        });

        if (res && res.data) {
          //console.log('AdditionalInput: companyData', companyData);
          updateContext({
            token: res.data.Token,
            content: res.data.Content,
            directors: [{ ...formData, ...data }],
            formPath: 'default',
            activeIndexPage: 3,
            loading: false,
          });
        } else {
          updateContext({ loading: false });
        }
      } catch (error) {
        console.log(error);
        handleSubmissionError(error);
      }
    }
    return false;
  };

  const handleSubmissionError = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
      updateContext({ loading: false, error: error.response.data.error });
      history.push('/empty-error');
    } else {
      updateContext({ loading: false, error: 'An unexpected error occurred.' });
    }
  };

  return (
    <>
      <div className={'col-2-list first-step-container'}>
        <div className="custom-navigation-block">
          <span>BUSINESS VERIFICATION</span>
        </div>
        <div className="custom-additional">
          <div className="custom-additional-header">
            <span>Additional Personal Information</span>
          </div>
          <DynamicForm ref={formRef} fields={formFields} onChange={handleFormDataChange}>
            {error && (
              <div className="flex w-full">
                <p dangerouslySetInnerHTML={{ __html: error }} className="error-text-no-flex custom-error-text"></p>
              </div>
            )}
            <div className="flex w-full">
              <div className="custom-additional-input">
                <StepButtons handleGotoPrev={handleGotoPrev} handleGotoNext={handleContinue} />
              </div>
            </div>
          </DynamicForm>
        </div>
      </div>
    </>
  );
};

export default AdditionalInput;
