import { useState } from 'react';

const useSignupLocalData = () => {

  const key = 'signupDetails';

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Error retrieving data from local storage:', error);
      return null;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error storing data in local storage:', error);
    }
  };

  const removeValue = () => {
    try {
      setValue(null);
      localStorage.removeItem(key);
      setStoredValue(null);
    } catch (error) {
      console.error('Error removing data from local storage:', error);
    }
  };

  return { storedValue, setValue, removeValue };
};

export default useSignupLocalData;