import React from 'react';
const CompanyMobileTable = ({ results, setSelectedCompany }) => {
  return (
    <div className="mobile-table-result-container">
      {results.map((row, index) => {
        return (
          <div className="table-result-parent" key={row.ACN + index}>
            <div className="table-result-item row-name">
              <div className="result-key">
                <input
                  id={`${index}`}
                  name={'selectedCompany'}
                  type="radio"
                  value={row.ACN}
                  onClick={() => setSelectedCompany(row)}
                />
                <p>{row.Name}</p>
              </div>
            </div>

            {row.ABN && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>ABN</p>
                </div>
                <div className="result-value">
                  <p>{row.ABN}</p>
                </div>
              </div>
            )}

            {row.ACN && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>ACN</p>
                </div>
                <div className="result-value">
                  <p>{row.ACN}</p>
                </div>
              </div>
            )}

            {row.Status && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>Status</p>
                </div>
                <div className="result-value">
                  <p>{row.Status}</p>
                </div>
              </div>
            )}

            {row.Type && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>Type</p>
                </div>
                <div className="result-value">
                  <p>{row.Type}</p>
                </div>
              </div>
            )}

            {row.AsicRegistrationDate && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>Registered Date</p>
                </div>
                <div className="result-value">
                  <p>{row.AsicRegistrationDate}</p>
                </div>
              </div>
            )}

            {row.TradingNames && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>Trading Names</p>
                </div>
                <div className="result-value">
                  <p>{row.TradingNames}</p>
                </div>
              </div>
            )}

            {row.Address && (
              <div className="table-result-item">
                <div className="result-key">
                  <p>Address</p>
                </div>
                <div className="result-value">
                  <p>{row.Address}</p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default CompanyMobileTable;
